import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import CloudIndexNav from '../../components/cloudIndexNav/cloudIndexNav'
import CloudIndexPerformance from '../../components/cloudIndexPerformance/cloudIndexPerformance'
import CloudIndexStats from '../../components/cloudIndexStats/cloudIndexStats'
// import CloudIndexResources from '../../components/cloudIndexResources/cloudIndexResources'

import report from '../../img/infrared-report.png'

export const query = graphql`
  query CloudIndexQuery {
    settings: allGoogleSettingsSheet {
      nodes {
        totalMarketCap
        medianRevenueMultiple
        medianRevenueGrowthRate
      }
    }
    redpoint: allGoogleRedpointIndexSheet {
      nodes {
        date
        close
      }
    }
    nasdaq: allGoogleNasdaqIndexSheet {
      nodes {
        date
        close
      }
    }
    snp500: allGoogleSp500IndexSheet {
      nodes {
        date
        close
      }
    }
    dowjones: allGoogleDowJonesIndexSheet {
      nodes {
        date
        close
      }
    }
  }
`

const CloudIndexPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout page='cloudIndex' hideContactBar version='1'>
      <SEO title='The Nasdaq Redpoint Cloud Infrastructure Software Index' description='' keywords={['']} imageFB={report} />

      <CloudIndexNav current='index' />

      <CloudIndexPerformance data={data} />

      <CloudIndexStats data={data} />

      {/*<CloudIndexResources />*/}

    </Layout>
  )
}

export default CloudIndexPage
